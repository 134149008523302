html, body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  font-size: 1.2rem;
}

* {
  box-sizing: border-box;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.layout__window {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: url("/src/images/background3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(5px);
}

.layout__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(240, 233, 248, 0.4);
  backdrop-filter: blur(5px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.layout__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex: 1;
}

.layout__footer {
  background: rgba(240, 233, 248, 0.4);
  backdrop-filter: blur(5px);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.userBox {
  border-radius: 0.5rem;
  min-width: 3rem;
  min-height: 3rem;
  padding: 1.5rem 2rem;
  cursor: move;
  color: #fff;
  font-weight: bold;
  background: rgba(93, 81, 106, 0.3);
  backdrop-filter: blur(6px);
  box-shadow: 0.15rem 0.15rem 0.4rem rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userBox_wiggle {
  -webkit-animation: wiggle 0.5s linear infinite normal;
  -moz-animation: wiggle 0.5s linear infinite normal;
  animation: wiggle 0.5s linear infinite normal;
}

.userBox_preview {
  background: rgba(33, 177, 255, 0.1);
  backdrop-filter: blur(2px);
}

.userBox_canDrop {
  background: rgba(179, 151, 191, 0.5);
}

.userBox_active {
  background: rgba(132, 111, 163, 0.5);
}

.userBox__sum {
  font-weight: normal;
  font-size: 1rem;
}

.userBox__contextMenuWrapper {
  position: relative;
}

.userBox__contextMenu {
  position: absolute;
  top: 0;
  right: 0;
}

.userBox__contextMenuIcon {
  color: white;
}

.historyPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.historySearch {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.historySearch >*:first-child {
  flex-grow: 1;
}

.historyList {
  overflow: auto;
  border-bottom: 1px solid #3e4387;
}

.historyPagination {

}

.transactionRow {
  width: 100%;
  text-align: left;
  padding: 0.25rem 0.5rem;
  color: #333;
}

.transactionRowValue {
  font-weight: bold;
}

.transactionRowAdditional {
  font-size: 0.75em;
  color: #444;
}

@keyframes wiggle {
  0% {
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
  25% {
    -ms-transform: rotate(-4deg); /* IE 9 */
    -webkit-transform: rotate(-4deg); /* Chrome, Safari, Opera */
    transform: rotate(-4deg);
  }
  50% {
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
  75% {
    -ms-transform: rotate(4deg); /* IE 9 */
    -webkit-transform: rotate(4deg); /* Chrome, Safari, Opera */
    transform: rotate(4deg);
  }
  100% {
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }
}

.category-grid {

}

.category-item {
  text-align: center;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  border: 1px solid #eeeeee;
}

.file-input {
  display: none;
}
